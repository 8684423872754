import gql from 'graphql-tag'


export const GET_CURRENT_USER = gql `
  query current_user {
    currentUser {
      id
      name
    }
  }
`

export const GET_ASSESSMENT_LIST = gql `
  query assessments($courseId: ID) {
    assessments(courseId: $courseId) {
        id
        title
        scores {
          id
          value
          student {
            id
            name
            email
          }
        }
        allStudentScores {
          id
          name
          email
          value
        }
        unscoredStudents {
          id
          name
          email
        }
        course {
          id
          name
          classroom {
            id
            name
          }
        }
    }
  }
`

export const GET_COURSE_LIST = gql `
  query courses {
    courses {
      id
      name
      classroom {
        id
        name
      }
    }
  }
`

export const GET_CLASSROOM_LIST = gql `
  query classrooms {
    classrooms {
      id
      students {
          id
      }
    }
  }
`

export const GET_ATTENDANCE_LIST = gql `
  query classroomAttendances($search: String, $startDate: String, $endDate: String, $classroomId: ID, $statusId: ID) {
    classroomAttendances(search: $search, startDate: $startDate, endDate: $endDate, classroomId: $classroomId, statusId: $statusId) {
      id
      date

      classroom {
        id
        name
      }

      studentAttendances {
        id
        createdAt
        student {
            id
            name
            classroom {
                id
                name
            }
        }

        status {
            id
            name
        }
      }
    }
}
`

export const GET_TEACHER_ATTENDANCE_LIST = gql `
  query teacherAttendances {
    teacherAttendances {
      id
      createdAt
      checkoutAt
      date
      imageUrl
      teacher {
          name
      }
    }
}
`

export const GET_GROUPED_ATTENDANCE_LIST = gql `
  query groupedClassroomAttendances {
    groupedClassroomAttendances {
      id
      date

      classrooms {
        id
        name
      }

      studentAttendances {
        id
        createdAt
        student {
            id
            name
            classroom {
                id
                name
            }
        }

        status {
            id
            name
        }
      }
    }
}
`

export const GET_TODAY_STUDENT_ATTENDANCE_LIST = gql `
query todayStudentAttendances($search: String, $startDate: String, $endDate: String, $classroomId: ID, $statusId: ID) {
  todayStudentAttendances(search: $search, startDate: $startDate, endDate: $endDate, classroomId: $classroomId, statusId: $statusId, showLate: true) {
    id
    createdAt
    student {
        id
        name
        classroom {
            id
            name
        }
    }

    status {
        id
        name
    }
  }
}
`

export const GET_TODAY_ATTENDANCE_LIST = gql `
  {
    todayClassroomAttendances {
        id
        date

        classroom {
          id
          name
        }

        studentAttendances {
            id
            createdAt
            student {
                id
                name
                classroom {
                    name
                }
            }

            status {
                id
                name
            }
        }
    }
  }
`

export const QUERY_GET_SELECTED_RETURN_FILTERS_CLIENT = gql`
  {
    selectReturnFilters @client
  }
`

export const GET_USER_LIST = gql `
  query users {
    users {
        id
        name
        email
        kind
    }
  }
`
export const GET_STUDENT_LIST = gql `
  query students {
    students {
        id
        name
        email
        kind
        classroom {
          name
        }
    }
  }
`
// export const GET_PROFILE_LIST = gql`
//   query profiles {
// 	  profiles {
//       id
//       name
//       email
//       totalAccounts
//       company {
//         id
//         name
//       }
//       type {
//         id
//         name 
//       }
//       status {
//         id
//         status
//       }
// 	  }
//   }
// `
// export const GET_TYPE_LIST = gql`
//   query profileTypes {
//     profileTypes {
//       id
//       name
//     }
//   }
// `
// export const GET_STATUS_LIST = gql`
//   query profileStatuses {
//     profileStatuses {
//       id
//       status
//     }
//   }
// `
// export const GET_COMPANY_LIST = gql`
//   query companies($search: String, $limit: Int) {
//     companies (search: $search, limit: $limit){
//       id
//       name
//     }
//   }
// `

// export const MUTATION_CREATE_COMPANY = gql`
//   mutation createCompany($input: CreateCompanyInput!) {
//     createCompany (input: $input) {
//       company {
//         id
//       }
//     }
//   }
// `

// export const MUTATION_UPDATE_PROFILE = gql`
//   mutation updateProfile($input: UpdateProfileInput!) {
//     updateProfile (input: $input) {
//       profile {
//         id
//         name
//       }
//     }
//   }
// `