import React from 'react'
import { useQuery } from '@apollo/client'

import { ContentLoaderTableOnly } from '../../../components/loader/loader.component'
import TableComponent from '../../../components/table/table.component'
import LabelComponent from '../../../components/label/label.component'
import LinkComponent from '../../../components/link/link.component'

import { ActionContainer, SortingContainer } from '../admin.styles'

import { GET_TODAY_STUDENT_ATTENDANCE_LIST } from '../../../config/graphql'

const AttendanceList = ({ dateRange, groupFilter }) => {
    console.log({ variables: {...dateRange, ...groupFilter} })
    const { loading, data } = useQuery(GET_TODAY_STUDENT_ATTENDANCE_LIST, { variables: {...dateRange, ...groupFilter} });
    const dataAttendance = data?.todayStudentAttendances || [];

    if (loading && !dataAttendance.length) {
      return <ContentLoaderTableOnly />
    } else {
      var idx = 0;
      return <div>
        <TableComponent mode='custom' template='1fr 3fr 4fr 1fr 2fr'>
          <div aria-label='head'>
            <div>
              <SortingContainer>No</SortingContainer>
            </div>
            <div>
              <SortingContainer>Waktu</SortingContainer>
            </div>
            <div>
              <SortingContainer>Nama</SortingContainer>
            </div>
            <div>
              <SortingContainer>Kelas</SortingContainer>
            </div>
            <div>
              <SortingContainer>Status</SortingContainer>
            </div>
          </div>
          <div aria-label='body'>
            {dataAttendance.map((studentAttendance) => {
                idx++;
                return (
                  <div key={idx}>
                    <div>
                      <LabelComponent mode='tagredmobile'>NO</LabelComponent>
                      {idx}
                    </div>
                    <div>
                      <LabelComponent mode='tagredmobile'>Tanggal</LabelComponent>
                      {studentAttendance.createdAt ? (new Date(Date.parse(studentAttendance.createdAt))).toLocaleTimeString("en-US", { hour12: false }) : '-'}
                    </div>
                    <div>
                      <LabelComponent mode='tagredmobile'>Nama</LabelComponent>
                      {(studentAttendance.student.name)}
                    </div>
                    <div>
                      <LabelComponent mode='tagredmobile'>Kelas</LabelComponent>
                      {(studentAttendance.student.classroom.name)}
                    </div>
                    <div style={{color: (studentAttendance.status.name == 'Telat' ? 'red' : null)}}>
                      <LabelComponent mode='tagredmobile'>Status</LabelComponent>
                      {(studentAttendance.status.name)}
                    </div>
                  </div>
                )
              })
            }
          </div> 
        </TableComponent>
      </div>
    }
}

export default AttendanceList;